<template>
  <div>
    <!-- 版本管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.versionName" clearable placeholder="请输入版本名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.versionManager" clearable placeholder="请输入版本负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" v-if="OA.includes('jcxx:bbgl:add')" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="versionTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="versionList" :total="versionTotal" :loading="tLoading" v-if="versionTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="version" show-overflow-tooltip label="版本号"></el-table-column>
        <el-table-column sortable="custom" prop="apkUrl" show-overflow-tooltip label="app下载地址"></el-table-column>
        <el-table-column sortable="custom" prop="content" show-overflow-tooltip label="内容"></el-table-column>
        <el-table-column sortable="custom" prop="appType" show-overflow-tooltip label="App类型">
          <template slot-scope="scope">
            <span v-if="scope.row.appType==1">android</span>
            <span v-if="scope.row.appType==2">ios</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="updType" show-overflow-tooltip label="更新类型">
          <template slot-scope="scope">
            <span v-if="scope.row.updType==1">普通更新</span>
            <span v-if="scope.row.updType==2">强制更新</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="onlineTime" show-overflow-tooltip label="上线时间"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" v-if="OA.includes('jcxx:bbgl:load')" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" v-if="OA.includes('jcxx:bbgl:editor')" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button size="mini" v-if="OA.includes('jcxx:bbgl:del')" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑版本' : '添加版本'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="版本号：" prop="version">
          <el-input placeholder="请输入版本号" style="width:316px;" v-model="form.version" clearable></el-input>
        </el-form-item>
        <el-form-item label="app下载地址：" prop="apkUrl">
          <el-input placeholder="请输入app下载地址" style="width:316px;" v-model="form.apkUrl" clearable></el-input>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <el-input  type="textarea" :rows="6"  v-model="form.content" placeholder="请输入内容" style="width:316px;">
          </el-input>
        </el-form-item>
        <el-form-item label="App类型：" prop="appType">
          <el-select style="width:316px;" clearable filterable v-model="form.appType" placeholder="请选择App类型">
            <el-option label="android" :value="1"></el-option>
            <el-option label="ios" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新类型：" prop="updType">
          <el-select style="width:316px;" clearable filterable v-model="form.updType" placeholder="请选择更新类型">
            <el-option label="普通更新" :value="1"></el-option>
            <el-option label="强制更新" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上线时间：" prop="onlineTime">
          <el-date-picker style="width:316px;"
            v-model="form.onlineTime" :picker-options="timeRange"
            value-format="yyyy-MM-dd HH:mm:ss" 
            type="datetime"
            placeholder="选择上线时间"
            >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="版本详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="版本号：" prop="version">
          <span v-text="details.version"></span>
        </el-form-item>
        <el-form-item label="app下载地址：" prop="apkUrl">
          <span v-text="details.apkUrl"></span>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <span v-text="details.content"></span>
        </el-form-item>
        <el-form-item label="App类型：" prop="appType">
          <span>
            {{ details.appType==1? "android" : "ios" }}
          </span>
        </el-form-item>
        <el-form-item label="更新类型：" prop="updType">
          <span>
            {{ details.updType==1? "普通更新" : "强制更新" }}
          </span>
        </el-form-item>
        <el-form-item label="上线时间：" prop="onlineTime">
          <span v-text="details.onlineTime"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'versionManage',
  components: {
    PagedTable
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      queryParams: {
        versionName: null,
        versionManager: null,
      },

      form:{
        id:'',
        version:'',
        apkUrl:'',
        content:'',
        appType:'',
        updType:'',
        remark:'',
        onlineTime:'',
      },
      details:{
        version:'',
        apkUrl:'',
        content:'',
        appType:'',
        updType:'',
        remark:'',
        onlineTime:'',
      },
      formRules:{
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        apkUrl: [{ required: true, message: '请输入app下载地址', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        appType: [{ required: true, message: '请选择App类型', trigger: 'blur' }],
        updType: [{ required: true, message: '请选择更新类型', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        onlineTime: [{ required: true, message: '选择上线时间', trigger: 'blur' }],
      },

      pageApi:'versionMList',
      insertApi:'versionAdd',
      editorApi:'versionEdit',
      deleteApi:'versionDelete',
      loadApi:'versionLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemVersion", ["versionList", "versionTotal"]),
  },
  methods: {
    ...mapActions("systemVersion",["versionMList", "versionAdd", "versionEdit", "versionDelete", "versionLoad"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>